import { httpClient } from "./RestService";

export const forgotPassword = async (data) => {
    return await  httpClient().post("/v1/education/auth/frgtpss", data);
}

export const profile = async () => {
    return await httpClient().get("/v1/education/user/profile");
}

export const updateProfile = async (data) => {
    return await httpClient().put("/v1/education/user/profile/" + data.id, data);
}