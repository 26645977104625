import React, { useState } from "react";
import StepLabel from "@mui/material/StepLabel";
import {
  Box,
  Container,
  Chip,
  Stack,
  Stepper,
  Step,
  Button,
} from "@mui/material";
import IndustryIcon from "@mui/icons-material/WarehouseRounded";
import ConceptIcon from "@mui/icons-material/TitleRounded";
import Review from "./Review";
import CaseTopics from "./CaseTopics";
import CaseSubTopics from "./CaseSubTopics";
import Company from "./Company";
import BusinessDevelopment from "./BusinessDevelopment";
import { useNavigate, useLocation } from "react-router-dom";
import PageHeader from "../../../../components/PageHeader";
import * as casestudyService from "../../services/CasestudyService";
import {
  BaseInfoLabel,
  createBaseInfoObject,
  validateBaseInfo,
  updateBaseInfoValues,
  handleBaseInfoInputChanges,
  BaseInfoPage,
} from "./baseInfo";
import {
  IndustryLabel,
  createIndustryObject,
  validateIndustry,
  updateIndustryValues,
  handleIndustryInputChanges,
  IndustryPage,
} from "./industry";
import {
  CompanyLabel,
  createCompanyObject,
  validateCompany,
  updateCompanyValues,
  handleCompanyInputChanges,
} from "./compny";
import {
  TopicsLabel,
  createTopicsObject,
  validateTopics,
  updateTopicsValues,
} from "./topic";
import {
  SubTopicsLabel,
  createSubTopicsObject,
  validateSubTopics,
  updateSubTopicsValues,
} from "./subtopic";
import {
  BizLabel,
  createBizObject,
  validateBiz,
  updateBizValues,
  handleBizInputChanges,
} from "./biz";
import { Completed } from "./Completed";
import Notification from "../../../../components/mui/Notification";
import LoadingButton from "@mui/lab/LoadingButton";
import Questions from "./Questions";

export default function New() {
  const { state } = useLocation();

  const initFormValues = () => {
    const baseInfo = state.content?.baseInfo
      ? state.content.baseInfo
      : createBaseInfoObject();
    const industry = state.content?.industry
      ? state.content.industry
      : createIndustryObject();
    const company = state.content?.company
      ? state.content.company
      : createCompanyObject();
    const topics = state.content?.topics
      ? state.content.topics
      : createTopicsObject();
    const subTopics = state.content?.subTopics
      ? state.content.subTopics
      : createSubTopicsObject();
    const biz = state.content?.biz ? state.content.biz : createBizObject();
    return { baseInfo, industry, company, topics, subTopics, biz };
  };

  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(initFormValues);
  const [nextBtnLoading, setNextBtnLoading] = useState();
  const [caseStudy, setCaseStudy] = useState({});
  const [hardRender, setHardRender] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [inProgessRequests, setInProgressRequests] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const course = state.course;

  const updateLoadingStatus = (name, value) => {
    setInProgressRequests((prevValues) => ({
      ...prevValues,
      [name]: {
        inProgress: value,
      },
    }));
  };

  const canSubmit = () => {
    let result = false;
    for (const key in inProgessRequests) {
      if (!inProgessRequests[key].inProgress) {
        result = true;
      } else result = false;
    }
    return result;
  };

  const steps = [
    {
      label: BaseInfoLabel,
      content: (
        <BaseInfoPage
          course={course}
          formValues={formValues}
          onInputChange={(e) => handleBaseInfoInputChanges(e, setFormValues)}
          updateValues={(name, value) =>
            updateBaseInfoValues(name, value, setFormValues)
          }
        />
      ),
      validationFn: validateBaseInfo,
      backgroundFn: [() => upsertBaseInfo()],
    },
    {
      label: IndustryLabel,
      content: (
        <IndustryPage
          course={course}
          formValues={formValues}
          onInputChange={(e) => handleIndustryInputChanges(e, setFormValues)}
          updateValues={(name, value) =>
            updateIndustryValues(name, value, setFormValues)
          }
        />
      ),
      validationFn: validateIndustry,
    },
    {
      label: CompanyLabel,
      content: (
        <Company
          formValues={formValues}
          onInputChange={(e) => handleCompanyInputChanges(e, setFormValues)}
          updateValues={(name, value) =>
            updateCompanyValues(name, value, setFormValues)
          }
        />
      ),
      validationFn: validateCompany,
      backgroundFn: [() => industryName()],
    },
    {
      label: TopicsLabel,
      content: (
        <CaseTopics
          course={course}
          formValues={formValues}
          updateValues={(name, value) =>
            updateTopicsValues(name, value, setFormValues)
          }
        />
      ),
      validationFn: validateTopics,
    },
    {
      label: SubTopicsLabel,
      content: (
        <CaseSubTopics
          course={course}
          formValues={formValues}
          updateValues={(name, value) =>
            updateSubTopicsValues(name, value, setFormValues)
          }
        />
      ),
      validationFn: validateSubTopics,
      backgroundFn: [() => concept()],
    },
    {
      label: BizLabel,
      content: (
        <BusinessDevelopment
          course={course}
          formValues={formValues}
          onInputChange={(e) => handleBizInputChanges(e, setFormValues)}
          updateValues={(name, value) =>
            updateBizValues(name, value, setFormValues)
          }
        />
      ),
      validationFn: validateBiz,
    },
    // {
    //   label: ProblemLabel,
    //   content: (
    //     <Problem
    //       formValues={formValues}
    //       onInputChange={(e) => handleProblemInputChanges(e, setFormValues)}
    //       updateValues={(name, value) =>
    //         updateProblemValues(name, value, setFormValues)
    //       }
    //     />
    //   ),
    //   validationFn: validateProblem,
    // },
    {
      label: "Preview",
      content: (
        <Review
          course={course}
          formValues={formValues}
          caseStudy={caseStudy}
          setState={setCaseStudy}
          updateLoadingStatus={updateLoadingStatus}
        />
      ),
      validationFn: (caseStudy) => caseStudy !== null,
    },
    {
      label: "Questions",
      content: <Questions course={course} formValues={formValues} />,
      validationFn: (caseStudy) => caseStudy !== null,
    },
  ];

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleSubmit = () => {
    if (canSubmit()) {
      const caseStudyRequest = {
        caseStudyId: formValues.caseStudyId,
        industry: formValues.industry.name,
        marketTerritory: formValues.company.territory,
        companyName: formValues.company.names[0],
        concept: formValues.concept,
      };

      casestudyService
        .create(caseStudyRequest)
        .then((res) => {
          console.log("creation result: ", res);
        })
        .catch((e) => {
          console.error(e);
          setNotify({
            isOpen: true,
            message: "Could not submit the case study. Please try again later",
            type: "error",
          });
        })
        .finally(() => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          // Scroll to the top of the page
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
  };

  const upsertBaseInfo = () => {
    setNextBtnLoading(true);
    const request = {
      id: formValues.caseStudyId,
      courseId: course.id,
      marketType: formValues.baseInfo.marketType,
      textBook: course.textBook,
      textbookChapterTitle: formValues.baseInfo.chapters[0],
    };

    casestudyService
      .upsertBaseInfo(request)
      .then((res) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          caseStudyId: res.data,
        }));
        moveNext();
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not submit a case study draft. Please try again later",
          type: "error",
        });
      })
      .finally(() => setNextBtnLoading(false));
  };

  const industryName = () => {
    setNextBtnLoading(true);
    casestudyService
      .getIndustryName(formValues.caseStudyId, formValues.company.names[0])
      .then((res) => {
        updateIndustryValues("name", res.data, setFormValues);
        moveNext();
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not get industry name. Please try again later",
          type: "error",
        });
      })
      .finally(() => setNextBtnLoading(false));
  };

  const concept = () => {
    setNextBtnLoading(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      textBook: course.textBook,
      chapterTitle: formValues.baseInfo.chapters[0],
      subTopics: formValues.subTopics.list.join(", "),
    };
    casestudyService
      .getConcept(request)
      .then((res) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          concept: res.data,
        }));
        sendProblemCreationRequest(res.data);
        moveNext();
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not get concept. Please try again later",
          type: "error",
        });
      })
      .finally(() => setNextBtnLoading(false));
  };

  const sendProblemCreationRequest = (concept) => {
    const request = {
      caseStudyId: formValues.caseStudyId,
      companyName: formValues.company.names[0],
      subTopics: formValues.subTopics.list.join(", "),
      country: formValues.industry.country,
      concept: concept,
      industryName: formValues.industry.name,
      competitors: formValues.company.competitors.join(", "),
    };
    casestudyService
      .createProblemStory(request)
      .then((res) =>
        console.log("Successfully sent a problem story creation request")
      )
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not send problem story creation request. Please try again later",
          type: "error",
        });
      });
  };

  const moveNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNext = () => {
    console.log("handleNext: ", formValues);
    const currentStep = steps[activeStep];
    if (currentStep.validationFn(formValues)) {
      if (currentStep.backgroundFn) {
        currentStep.backgroundFn.forEach((func) => func());
      } else {
        moveNext();
      }
    } else {
      console.error("Not valid form:", formValues);
      setHardRender(!hardRender);
    }
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);

  //   // Scroll to the top of the page
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  const onCancel = () => {
    navigate(-1, { replace: true });
  };

  const handleReset = () => {
    setCaseStudy({});
    setFormValues(initFormValues);
    setActiveStep(0);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title="New Case Configuration" isChild={true} />
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <Completed onReset={handleReset} />
        ) : (
          <React.Fragment>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Stack direction="row" spacing={1}>
                  {formValues.concept ? (
                    <Chip icon={<ConceptIcon />} label={formValues.concept} />
                  ) : null}
                  {formValues.industry?.name ? (
                    <Chip
                      icon={<IndustryIcon />}
                      label={formValues.industry.name}
                    />
                  ) : null}
                </Stack>
              </Container>

              {steps[activeStep].content}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button color="secondary" onClick={onCancel} sx={{ mr: 1 }}>
                  Cancel
                </Button>
                {/* <Button
                  color="inherit"
                  disabled={activeStep === 0 || activeStep === steps.length - 1}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button> */}
                <Box sx={{ flex: "1 1 auto" }} />

                {activeStep === steps.length - 1 ? (
                  <Button onClick={handleSubmit}>Submit</Button>
                ) : (
                  <LoadingButton onClick={handleNext} loading={nextBtnLoading}>
                    Next
                  </LoadingButton>
                )}
              </Box>
            </Container>
          </React.Fragment>
        )}
      </Box>

      <Notification
        notify={notify}
        setNotify={setNotify}
        autoHideDuration={4000}
      />
    </Container>
  );
}
