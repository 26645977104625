import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Button,
  Toolbar,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  IconButton,
  MenuItem,
  Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppContext } from "../App";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { logout } from "../services/AuthService";
import { logoutSuccess } from "../reducers/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { LOGIN_ROUTE } from "../helper/Constants";

const pages = [];
const useStyles = makeStyles((theme) => ({
  logo: {
    height: "6em",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      height: "5em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "4.5em",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //   ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    minHeight: `calc(100vh - 100px)`,
  },
}));

export default function ResponsiveLayout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const { appState, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    if (appState.isLoggedIn) {
      setAnchorElUser(event.currentTarget);
    } else {
      navigate(LOGIN_ROUTE);
    }
  };

  //   const handleActivites = () => {
  //       handleCloseUserMenu()
  //   }

  //   const handleProfileNavigation = () => {
  //     navigate(PROFILE_ROUTE);
  //     handleCloseUserMenu();
  //   };

  const handleNewEvent = () => {
    handleCloseNavMenu();
  };

  const handleLogout = () => {
    logout().then((res) => {
      dispatch(logoutSuccess());
      handleCloseUserMenu();
      navigate("/");
    });
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position="static" id="header">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                mr: 1,
                mt: 1,
                mb: 1,
                cursor: "pointer",
              }}
              component="img"
              onClick={() => navigate("/")}
              className={classes.logo}
              alt="Education"
              src="/resources/logo_school.svg"
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Business Case Lab
            </Typography>

            {/* change it to  display: { xs: 'flex', md: 'none' } to be displayed */}
            <Box sx={{ flexGrow: 1, display: { xs: ["flex", "none"] } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleNewEvent}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                mr: 1,
                cursor: "pointer",
              }}
              component="img"
              onClick={() => navigate("/")}
              className={classes.logo}
              alt="Education"
              src="/resources/logo_school.svg"
            />
            <Typography
              variant="h5"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Business Case Lab
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                ml: 5,
                mr: 5,
                mt: 0,
                display: { xs: "none", md: "flex" },
              }}
            >
              {pages.map((page) => (
                <Button
                  variant="contained"
                  key={page}
                  onClick={handleNewEvent}
                  sx={{ color: "orange", display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="user info">
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  {appState.isLoggedIn ? (
                    <>
                      <Typography
                        sx={{
                          mr: 1,
                          fontSize: {
                            lg: "0.8rem",
                            md: "0.7rem",
                            sm: "0.6rem",
                            xs: "0.5rem",
                          },
                        }}
                      >
                        {appState.userInfo?.firstName}{" "}
                        {matchesSM ? appState.userInfo?.lastName : null}
                      </Typography>
                      <Avatar src={appState.userInfo?.photoUrl} />
                    </>
                  ) : (
                    <Typography textAlign="center" variant="subtitle2">
                      SIGN IN
                    </Typography>
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* <MenuItem onClick={handleActivites}>
                        <Typography textAlign="center">My Activities</Typography>
                    </MenuItem> 
                 <MenuItem onClick={handleProfileNavigation}>
                  <Typography textAlign="center">My Profile</Typography>
                </MenuItem> */}
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
        {appState.isLoggedIn && !appState.userInfo?.hasPromptPermission && (
          <Alert
            severity="warning"
            action={
              <Button color="inherit" size="small">
                Contact us
              </Button>
            }
          >
            No more credits avaiable to create new case. Please contact us to
            get unblocked!
          </Alert>
        )}
      </AppBar>

      {/* main content */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </>
  );
}
