import React from "react";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Card, Typography } from "@mui/material";
import IconWithText from "../../../../components/IconWithText";
import { upperCaseFirstLetter } from "../../../../helper/TextHelper";
import { MuiButton } from "../../../../components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

export default function AssignmentCard({
  assignment,
  handleNotification,
  handleDelete,
  handleGroups,
}) {
  const copyToClipboard = (text) => {
    // Use navigator clipboard API if it's supported
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      handleNotification();
    } else {
      // Fallback: create a temporary textarea to copy the text
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      handleNotification();
    }
  };

  return (
    <Card elevation={1} sx={{ m: 0.5 }}>
      <CardHeader
        title={
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {upperCaseFirstLetter(assignment.title)}
          </Typography>
        }
        subheader={"Due date: " + assignment.dueDate}
      />

      <CardContent>
        <IconWithText
          icon={<ContentCopyIcon />}
          text={assignment.id}
          onClick={() => copyToClipboard(assignment.id)}
        />
        {assignment.promoCode && (
          <IconWithText
            icon={<MoneyOffIcon />}
            text={assignment.promoCode}
            onClick={() => copyToClipboard(assignment.promoCode)}
          />
        )}
      </CardContent>

      <CardActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <MuiButton
          size="small"
          text="Groups"
          variant="text"
          onClick={handleGroups}
        />
        <IconButton color="secondary" onClick={handleDelete}>
          <DeleteOutlinedIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
