import React from "react";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import FactoryIcon from "@mui/icons-material/Factory";
import StorefrontIcon from "@mui/icons-material/Storefront";
// import PostAddIcon from "@mui/icons-material/PostAdd";
import { Card, Avatar, Typography } from "@mui/material";
import IconWithText from "../../../../../components/IconWithText";
import { formatDate } from "../../../../../helper/DateHelper";
import { upperCaseFirstLetter } from "../../../../../helper/TextHelper";
import { MuiButton } from "../../../../../components";

export default function CaseStudyCard({
  caseStudy,
  handleEdit,
  handleView,
  handleDelete,
  handleTeachingNotes,
  handlePdf,
  handleAssignments,
}) {
  return (
    <Card elevation={1} sx={{ m: 0.5 }}>
      <CardHeader
        action={
          <IconButton onClick={handlePdf}>
          <DownloadIcon />
        </IconButton>
        }
        avatar={
          caseStudy.status === "ACTIVE" ? (
            <Avatar sx={{ bgcolor: "#008000" }}>
              <SpellcheckIcon />
            </Avatar>
          ) : (
            <Avatar sx={{ bgcolor: "#9e9e9e" }}>
              {caseStudy.status?.charAt(0)}
            </Avatar>
          )
        }
        title={
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {caseStudy.title}
          </Typography>
        }
        subheader={"Created on: " + formatDate(caseStudy.createdAt)}
      />

      <CardContent>
        <IconWithText
          icon={<FactoryIcon />}
          text={upperCaseFirstLetter(caseStudy.industry)}
        />
        <IconWithText
          icon={<StorefrontIcon />}
          text={caseStudy.marketType.toUpperCase()}
        />
      </CardContent>

      <CardActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <MuiButton
          size="small"
          text="Projects"
          variant="text"
          onClick={handleAssignments}
        />
        {/* {note.userId && note.userId !== null ? ( */}
        <IconButton color="secondary" onClick={handleDelete}>
          <DeleteOutlinedIcon />
        </IconButton>
        {/* <IconButton onClick={handleView}>
        import VisibilityIcon from "@mui/icons-material/Visibility";
          <VisibilityIcon />
        </IconButton> */}
        {/* <IconButton onClick={handleTeachingNotes}>
        import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
          <SpeakerNotesIcon />
        </IconButton> */}
        {/* <IconButton onClick={handleAssignment}>
          <PostAddIcon />
        </IconButton> */}

        {/* ) : null} */}
        {/* {note.userId && note.userId !== null ? ( */}
        {/* <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEdit(content)}
          >
            <EditOutlinedIcon />
          </IconButton> */}
        {/* ) : null} */}
        {/* <IconButton color="success" onClick={() => handleCasestudy(content.id)}>
          <AssignmentIcon />
        </IconButton> */}

        {/* <IconButton aria-label="edit" color="primary" onClick={handleEdit}>
          <EditOutlinedIcon />
        </IconButton> */}
      </CardActions>
    </Card>
  );
}
