export const LOGIN_ROUTE = "/login"
export const BOARD_ROUTE = "/board"
export const BOARD_NEW_ROUTE = BOARD_ROUTE + "/new"
export const BOARD_INVITATION_ROUTE = BOARD_ROUTE + "/invitation"
export const PROFILE_ROUTE = "/user/profile"
export const USER_ACTIVITIES_ROUTE = "/user/activities"

export const NOTE_LIST_ROUTE = "/note/list"
export const VIDEO_LIST_ROUTE = "/video/list"

export const GIFT_FLOWER_CONTRIBUTION_ROUTE = "/flower/upsert"
export const GIFT_SUGGESTION_LIST_ROUTE = "/suggestion/list"
export const GIFT_SUGGESTION_DETAILS_ROUTE = "/suggestion/details"
export const GIFT_SUGGESTION_UPSERT_ROUTE = "/suggestion/upsert"
export const PAYMENT_ROUTE = "/payment"
export const PAYMENT_IRR_ROUTE = "/payment/irr"



// Cloned Backend PaymentStatus 
export const PAYMENT_STATUS_INTENDED = "INTENDED"
export const PaymentStatus = new Map([
  ["PAID", 'Paid'],
  ["PENDING_APPROVAL", 'Processing'],
  ["INTENDED", 'Pending'],
]);
  