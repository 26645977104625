export const uploadVideo = 
    async (file) => {
        const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`;
        const formData = new FormData();
        formData.append("file", file);
        formData.append(
            "upload_preset",
            process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
        );
        const response = await fetch(url, {
            method: "post",
            body: formData,
        });
        return response.json();
    }

async function digestMessage(message) {
    const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    return hashHex;
}

export const deleteVideo = 
    async (data) => {
        const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/` + data.resourceType + `/destroy`;
        var timestamp = Math.round((new Date()).getTime()/1000);

        var toBeHashed = "public_id=" + data.publicId + "&timestamp=" + timestamp + process.env.REACT_APP_CLOUDINARY_API_SECRET;
        const signature = await digestMessage(toBeHashed);

        const formData = new FormData();
        formData.append("api_key", process.env.REACT_APP_CLOUDINARY_API_KEY);
        formData.append("public_id", data.publicId);
        formData.append("timestamp", timestamp);
        formData.append("signature", signature);
        const response = await fetch(url, {
            method: "post",
            body: formData,
        });
        return response;

        
    }

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const uploadProfilePhoto = 
    async (file) => {
        const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`;
        const formData = new FormData();
        formData.append("file", file);
        formData.append(
            "upload_preset",
            process.env.REACT_APP_CLOUDINARY_PROFILE_UPLOAD_PRESET
        );
        const response = await fetch(url, {
            method: "post",
            body: formData,
        })
        .then(handleErrors);

        return response.json();
    }


export const uploadImage = 
    async (file) => {
        const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`;
        const formData = new FormData();
        formData.append("file", file);
        formData.append(
            "upload_preset",
            process.env.REACT_APP_CLOUDINARY_IMAGE_UPLOAD_PRESET
        );
        const response = await fetch(url, {
            method: "post",
            body: formData,
        });
        return response.json();
    }

export const deleteImage = 
    async (data) => {
        const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/` + data.imageResourceType + `/destroy`;
        var timestamp = Math.round((new Date()).getTime()/1000);

        var toBeHashed = "public_id=" + data.imagePublicId + "&timestamp=" + timestamp + process.env.REACT_APP_CLOUDINARY_API_SECRET;
        const signature = await digestMessage(toBeHashed);

        const formData = new FormData();
        formData.append("api_key", process.env.REACT_APP_CLOUDINARY_API_KEY);
        formData.append("public_id", data.imagePublicId);
        formData.append("timestamp", timestamp);
        formData.append("signature", signature);
        const response = await fetch(url, {
            method: "post",
            body: formData,
        });
        return response;

        
    }